import { configureStore, createSlice } from "@reduxjs/toolkit";

const hashconnectSlice = createSlice({
  name: "hashconnectSlice",
  initialState: {
    isConnected: false,
    accountIds: [] as string[],
    pairingString: "",
    accountInfo: null as any,
    accountBalance: null as any,
    network: "",
  },
  reducers: {
    setIsConnected: (state, action: { payload: boolean }) => {
      state.isConnected = action.payload;
    },
    setAccountIds: (state, action: { payload: string[] }) => {
      state.accountIds = action.payload;
    },
    setPairingString: (state, action: { payload: string }) => {
      state.pairingString = action.payload;
    },
    setAccountInfo: (state, action: { payload: any }) => {
      state.accountInfo = action.payload;
    },
    setAccountBalance: (state, action: { payload: any }) => {
      state.accountBalance = action.payload;
    },
    setNetwork: (state, action: { payload: string }) => {
      state.network = action.payload;
    },
    clearAccountInfo: (state) => {
      state.accountInfo = null;
      state.accountIds = [];
      state.isConnected = false;
      state.pairingString = "";
      state.accountBalance = null;
      state.network = "";
    },
  },
});

// config the store
export const store = configureStore({
  reducer: {
    hashconnect: hashconnectSlice.reducer,
  },
});

export type AppStore = ReturnType<typeof store.getState>;

export const actions = {
  hashconnect: hashconnectSlice.actions,
};
