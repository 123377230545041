// src/pages/admin/Raffle.tsx

import React from 'react';

const Raffle = () => {
  return (
    <iframe
      src="/raffle.html"
      title="Raffle"
      style={{
        position: 'absolute',
        top: '20px', // Add 20px to the top margin
        left: 0,
        width: '100%',
        height: 'calc(100% - 20px)', // Adjust the height accordingly
        border: 'none',
      }}
    />
  );
};

export default Raffle;

