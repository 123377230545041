import React, { useState } from 'react';
import { Stack, Box, Typography, Button, TextField, CircularProgress } from '@mui/material';
import { useSelector } from 'react-redux';
import { AppStore } from '../../store';
import axios from 'axios';

const ApiKey = process.env.REACT_APP_API_KEY;

const CreateNFT = () => {
  const [name, setName] = useState('ELFC Ticket');
  const [symbol, setSymbol] = useState('ELFC-TICKET');
  const [maxSupply, setMaxSupply] = useState('infinite');
  const [royalty, setRoyalty] = useState('99.99');
  const [password, setPassword] = useState('');
  const [result, setResult] = useState('');
  const [loading, setLoading] = useState(false);
  const { accountIds } = useSelector((state: AppStore) => state.hashconnect);

  const accountId = accountIds[0];

  const handleCreateNFT = async () => {
    if (!password || !name || !symbol || !maxSupply || !royalty) {
      setResult('All fields are required');
      return;
    }

    setLoading(true);
    setResult('');

    try {
      const response = await axios.post('https://elfc-server.azurewebsites.net/api/ticket/create', {
        accountId,
        password,
        name,
        symbol,
        maxSupply,
        royalty: parseFloat(royalty)
      }, {
        headers: { 'x-api-key': ApiKey, 'Content-Type': 'application/json' }
      });

      setResult(`NFT creation complete, Token ID: ${response.data.tokenId}`);
    } catch (error: any) {
      console.error('Error creating NFT:', error);
      const errorMessage = error.response?.data || error.message || 'Unknown error';
      setResult(`${errorMessage}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Stack spacing={3} alignItems="center" width="100%" style={{ marginTop: '20px', marginBottom: '-50px' }}>
      <Typography variant="h3">Create NFT</Typography>

      <Box width="400px" maxWidth="80%" sx={{ paddingTop: '0px' }}>
        <Typography>Name:</Typography>
        <TextField
          color="primary"
          variant="standard"
          fullWidth
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Enter NFT name"
        />
      </Box>

      <Box width="400px" maxWidth="80%" sx={{ paddingTop: '0px' }}>
        <Typography>Symbol:</Typography>
        <TextField
          color="primary"
          variant="standard"
          fullWidth
          value={symbol}
          onChange={(e) => setSymbol(e.target.value)}
          placeholder="Enter NFT symbol"
        />
      </Box>

      <Box width="400px" maxWidth="80%" sx={{ paddingTop: '0px' }}>
        <Typography>Max Supply:</Typography>
        <TextField
          color="primary"
          variant="standard"
          fullWidth
          value={maxSupply}
          onChange={(e) => setMaxSupply(e.target.value)}
          placeholder="Enter max supply (e.g., infinite or a number)"
        />
      </Box>

      <Box width="400px" maxWidth="80%" sx={{ paddingTop: '0px' }}>
        <Typography>Royalty (%):</Typography>
        <TextField
          color="primary"
          variant="standard"
          fullWidth
          value={royalty}
          onChange={(e) => setRoyalty(e.target.value)}
          placeholder="Enter royalty percentage"
        />
      </Box>

      <Box width="400px" maxWidth="80%" sx={{ paddingTop: '0px' }}>
        <Typography>Password:</Typography>
        <TextField
          color="primary"
          variant="standard"
          type="password"
          fullWidth
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Enter password for authentication"
        />
      </Box>

      <Stack direction="column" spacing={2} sx={{ paddingTop: '20px' }}>
        <Button
          variant="contained"
          sx={{ backgroundColor: 'blurple.main', color: 'text.primary', width: '200px' }}
          onClick={handleCreateNFT}
          disabled={loading}>
          {loading ? <CircularProgress size={24} /> : 'CREATE NFT'}
        </Button>
      </Stack>

      <Box width="400px" maxWidth="80%" sx={{ marginTop: '20px' }}>
        <TextField
          color="primary"
          variant="standard"
          fullWidth
          value={result}
          multiline
          rows={2}
          InputProps={{
            readOnly: true,
            disableUnderline: true,
          }}
          sx={{
            '& .MuiInputBase-input': {
              color: 'text.secondary',
              fontSize: '0.75rem',
              fontWeight: 300,
              textAlign: 'center',
            }
          }}
        />
      </Box>
    </Stack>
  );
};

export default CreateNFT;
