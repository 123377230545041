import React, { useState, useEffect } from 'react';
import { Stack, Box, Typography, Button, TextField, CircularProgress } from '@mui/material';
import { useSelector } from 'react-redux';
import { AppStore } from '../../store';
import { patchAccount, verifyKey, verifyPassword, getAccount } from '../../helpers/serverCalls';

const ApiKey = process.env.REACT_APP_API_KEY;

const Settings = () => {
  const [privateKey, setPrivateKey] = useState('');
  const [password, setPassword] = useState('');
  const [result, setResult] = useState('');
  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingVerifyKey, setLoadingVerifyKey] = useState(false);
  const [showSecrets, setShowSecrets] = useState(false);
  const [isPrivateKeyValid, setIsPrivateKeyValid] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [storedPrivateKey, setStoredPrivateKey] = useState('');
  const [storedPassword, setStoredPassword] = useState('');
  const { accountIds, network } = useSelector((state: AppStore) => state.hashconnect);

  const accountId = accountIds[0];

  // Validate the private key
  const validatePrivateKey = (key: string) => {
    const hexRegex = /^[0-9a-fA-F]+$/;
    return key.length >= 30 && hexRegex.test(key);
  };

  // Validate the password
  const validatePassword = (pwd: string) => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return passwordRegex.test(pwd);
  };

  const fetchAccountInfo = async () => {
    try {
      const response = await getAccount(accountId);
      if (response.PrivateKey && response.PrivateKey !== 'Not Set') {
        setStoredPrivateKey(response.PrivateKey);
      }
      if (response.Password && response.Password !== 'Not Set') {
        setStoredPassword(response.Password);
      }
    } catch (error: any) {
      console.error('Error fetching account info:', error);
      setResult('Error fetching account info');
    }
  };

  useEffect(() => {
    fetchAccountInfo();
  }, [accountId]);

  useEffect(() => {
    setIsPrivateKeyValid(validatePrivateKey(privateKey));
  }, [privateKey]);

  useEffect(() => {
    setIsPasswordValid(validatePassword(password));
  }, [password]);

  const handleSaveKey = async () => {
    setResult('');
    if (!isPrivateKeyValid || !isPasswordValid) {
      setResult("Invalid private key or password.");
      return;
    }
    setLoadingSave(true);
    try {
      if (!ApiKey) {
        throw new Error("API key is missing.");
      }
      const response = await patchAccount(accountId, privateKey, password);
      setResult(response);

      // Fetch the updated account details after saving settings
      await fetchAccountInfo();
    } catch (error: any) {
      console.error('Error saving settings:', error);
      const errorMessage = error.response?.data || error.message || 'Unknown error';
      setResult(`${errorMessage}`);
    } finally {
      setLoadingSave(false);
    }
  };

  const handleVerifyKey = async () => {
    setResult('');
    setLoadingVerifyKey(true);
    try {
      if (!ApiKey) {
        throw new Error("API key is missing.");
      }
      const response = await verifyKey(accountId, password, network);
      setResult(response);
    } catch (error: any) {
      console.error('Error verifying key:', error);
      const errorMessage = error.response?.data || error.message || 'Unknown error';
      setResult(`${errorMessage}`);
    } finally {
      setLoadingVerifyKey(false);
    }
  };

  const handleVerifyPassword = async () => {
    setResult('');
    try {
      if (!ApiKey) {
        throw new Error("API key is missing.");
      }
      const response = await verifyPassword(accountId, password);
      setResult(response);
    } catch (error: any) {
      console.error('Error verifying password:', error);
      const errorMessage = error.response?.data || error.message || 'Unknown error';
      setResult(`${errorMessage}`);
    }
  };

  const handleShowSecrets = () => {
    setResult('');
    setShowSecrets(!showSecrets);
  };

  const getPasswordRequirements = () => {
    const requirements = [
      { text: 'At least 8 characters in length', valid: password.length >= 8 },
      { text: 'At least 1 uppercase letter', valid: /[A-Z]/.test(password) },
      { text: 'At least 1 lowercase letter', valid: /[a-z]/.test(password) },
      { text: 'At least 1 digit', valid: /\d/.test(password) },
      { text: 'At least 1 symbol', valid: /[@$!%*?&]/.test(password) }
    ];

    return requirements.map(req => (
      <Typography key={req.text} style={{ color: req.valid ? 'green' : 'red', fontSize: '0.75rem', fontWeight: 300 }}>
        {req.text}
      </Typography>
    ));
  };

  return (
    <Stack spacing={3} alignItems="center" width="100%" style={{ marginTop: '20px', marginBottom: '-50px'}}>
      <Typography variant="h3">Settings</Typography>

      <Box width="400px" maxWidth="80%" sx={{ paddingTop: '0px' }}>
        <Typography>Private Key:</Typography>
        <TextField
          color="primary"
          variant="standard"
          type={showSecrets ? 'text' : 'password'}
          fullWidth
          value={privateKey}
          onChange={(e) => {
            setPrivateKey(e.target.value);
            setIsPrivateKeyValid(validatePrivateKey(e.target.value));
            setResult('');
          }}
          placeholder="Enter key only to save settings"
        />
      </Box>

      <Box width="400px" maxWidth="80%">
        <Typography>Password:</Typography>
        <TextField
          color="primary"
          variant="standard"
          type={showSecrets ? 'text' : 'password'}
          fullWidth
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
            setIsPasswordValid(validatePassword(e.target.value));
            setResult('');
          }}
          placeholder="Enter password for all actions"
        />
      </Box>

      {password.length > 0 && !isPasswordValid && (
        <Box width="400px" maxWidth="80%">
          {getPasswordRequirements()}
        </Box>
      )}

      <Stack direction="column" spacing={2} sx={{ paddingTop: '0px' }}>
        <Button
          variant="contained"
          sx={{
            backgroundColor: 'blurple.main',
            color: 'text.primary',
            '&:hover': {
              backgroundColor: 'blurple.light',
            },
            width: '200px'
          }}
          onClick={handleShowSecrets}
          disabled={!isPrivateKeyValid && !isPasswordValid}
        >
          {showSecrets ? 'Hide Secrets' : 'Show Secrets'}
        </Button>
        <Button
          variant="contained"
          sx={{ backgroundColor: 'blurple.main', color: 'text.primary', width: '200px' }}
          onClick={handleSaveKey}
          disabled={loadingSave || !isPrivateKeyValid || !isPasswordValid}
        >
          {loadingSave ? <CircularProgress size={24} /> : 'Save Settings'}
        </Button>
        <Button
          variant="contained"
          sx={{ backgroundColor: 'blurple.main', color: 'text.primary', width: '200px' }}
          onClick={handleVerifyPassword}
          disabled={!isPasswordValid || !storedPassword}
        >
          Check Password
        </Button>
        <Button
          variant="contained"
          sx={{ backgroundColor: 'blurple.main', color: 'text.primary', width: '200px' }}
          onClick={handleVerifyKey}
          disabled={!isPasswordValid || !storedPrivateKey || !storedPassword}
        >
          {loadingVerifyKey ? <CircularProgress size={24} /> : 'Verify Private Key'}
        </Button>
      </Stack>

      <Box width="400px" maxWidth="80%" sx={{ paddingTop: '0px' }}>
        <TextField
          color="primary"
          variant="standard"
          fullWidth
          value={result}
          multiline
          rows={2}
          InputProps={{
            readOnly: true,
            disableUnderline: true,
          }}
          sx={{
            '& .MuiInputBase-input': {
              color: 'text.secondary',
              fontSize: '0.75rem',
              fontWeight: 300,
              textAlign: 'center',
            }
          }}
        />
      </Box>
    </Stack>
  );
};

export default Settings;
