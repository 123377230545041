import React, { useState, useEffect } from 'react';
import { Stack, Box, Typography, Button, TextField, CircularProgress, MenuItem, Select } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { AppStore } from '../../store';
import { createMemberSnapshot, getMemberSnapshot, queryNFT, getHeaders } from '../../helpers/serverCalls';
import { fetchAccountBalance } from '../../components/hashconnect/hashconnect-client';
import axios from 'axios';

const Membership = () => {
  const [view, setView] = useState('summary');
  const [exclude, setExclude] = useState(false); 
  const [treasuryId, setTreasuryId] = useState('');
  const [password, setPassword] = useState('');
  const [quantity, setQuantity] = useState('');
  const [snapshotData, setSnapshotData] = useState([]);
  const [result, setResult] = useState('');
  const [loadingSnapshot, setLoadingSnapshot] = useState(false);
  const [loadingView, setLoadingView] = useState(false);
  const [loadingAirdrop, setLoadingAirdrop] = useState(false);
  const [loadingMint, setLoadingMint] = useState(false); 
  const { accountIds } = useSelector((state: AppStore) => state.hashconnect);
  const dispatch = useDispatch();

  const accountId = accountIds[0];

  useEffect(() => {
    if (view) {
      setSnapshotData([]);
      setResult('');
    }
  }, [view]);

  useEffect(() => {
    const fetchTreasuryId = async () => {
      try {
        const nftData = await queryNFT(accountId, "Gold");
        if (nftData.length > 0) {
          setTreasuryId(nftData[0].TreasuryID);
        }
      } catch (error) {
        console.error('Error fetching Treasury ID:', error);
      }
    };
    fetchTreasuryId();
  }, [accountId]);

  const handleCreateSnapshot = async () => {
    setLoadingSnapshot(true);
    setResult(''); // Clear the result field when starting
    try {
      await createMemberSnapshot(accountId, true);
      setResult('Snapshot created successfully.');
    } catch (error: any) {
      setResult(`Error creating snapshot: ${error.message}`);
    } finally {
      setLoadingSnapshot(false);
    }
  };

  const handleViewSnapshot = async () => {
    setLoadingView(true);
    setResult(''); // Clear the result field when starting
    try {
      const excludeParam = exclude ? `${accountId},${treasuryId}` : '';
      const data = await getMemberSnapshot(accountId, view, null, true, excludeParam.toString());
      setSnapshotData(data);
      setResult('Snapshot retrieved successfully');
    } catch (error: any) {
      setResult(`Error viewing snapshot: ${error.message}`);
    } finally {
      setLoadingView(false);
    }
  };

  const handleMintTickets = async () => {
    const quantityNum = parseInt(quantity, 10);
  
    if (isNaN(quantityNum) || quantityNum < 1 || quantityNum > 1000) {
      setResult('Quantity should be between 1 and 1000');
      return;
    }
  
    if (!password) {
      setResult('Password is required for minting');
      return;
    }
  
    setLoadingMint(true);

    try {
      // Start the minting process
      const response = await axios.post('https://elfc-server.azurewebsites.net/api/ticket/mint', {
        accountId,
        password,
        quantity: quantityNum
      }, {
        headers: getHeaders()
      });

      const { requestId } = response.data;

      // Listen for updates using SSE
      const eventSource = new EventSource(`https://elfc-server.azurewebsites.net/api/ticket/mint/status/${requestId}`);

      eventSource.onmessage = function (event) {
        const data = JSON.parse(event.data);
        if (data.error) {
          setResult(data.error);
          eventSource.close();
          setLoadingMint(false);
          return;
        }
        setResult(data.message || `Minted ${data.minted} out of ${data.total} NFTs\n`);
        if (data.completed) {
          eventSource.close();
          setLoadingMint(false);
        }
      };

      eventSource.onerror = function (err) {
        console.error('EventSource failed:', err);
        eventSource.close();
        setLoadingMint(false);
      };

      await fetchAccountBalance(dispatch);

    } catch (error: any) {
      console.error('Error minting NFTs:', error);
      const errorMessage = error.response?.data || error.message || 'Unknown error';
      setResult(`${errorMessage}`);
      setLoadingMint(false);
    }
  };
   
  const handleAirdropTickets = async () => {
    if (!password) {
      setResult('Password is required for airdrop');
      return;
    }

    setLoadingAirdrop(true);

    try {
      // Start the airdrop process
      const response = await axios.post('https://elfc-server.azurewebsites.net/api/ticket/airdrop', {
        accountId,
        password
      }, {
        headers: getHeaders()
      });

      const { requestId } = response.data;

      // Listen for updates using SSE
      const eventSource = new EventSource(`https://elfc-server.azurewebsites.net/api/ticket/airdrop/status/${requestId}`);

      eventSource.onmessage = function (event) {
        const data = JSON.parse(event.data);
        
        if (data.error) {
          setResult(data.error);
          eventSource.close();
          setLoadingAirdrop(false);
          return;
        }

        setResult(data.message || `Airdropped ${data.airdropped} out of ${data.total} NFTs\n`);

        if (data.completed) {
          eventSource.close();
          setLoadingAirdrop(false);
        }
      };

      eventSource.onerror = function (err) {
        console.error('EventSource failed:', err);
        eventSource.close();
        setLoadingAirdrop(false);
      };

      await fetchAccountBalance(dispatch);

    } catch (error: any) {
      console.error('Error airdropping NFTs:', error);
      const errorMessage = error.response?.data || error.message || 'Unknown error';
      setResult(`${errorMessage}`);
      setLoadingAirdrop(false);
    }
  };

  const formatTimestamp = (timestamp: string) => {
    return new Date(timestamp).toISOString().replace('T', ' ').replace(/\..+/, '');
  };

  const renderFullTable = () => (
    <Box width="600px" maxWidth="80%" sx={{ marginBottom: '30px', fontSize: '0.75em' }}>
      <table style={{ width: '100%', textAlign: 'center', borderCollapse: 'collapse' }}>
        <thead>
          <tr style={{ borderBottom: '1px solid' }}>
            {Object.keys(snapshotData[0]).filter(key => key !== 'Timestamp').map((key) => (
              <th key={key}>{key}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {snapshotData.map((item, index) => (
            <tr key={index}>
              {Object.entries(item).filter(([key]) => key !== 'Timestamp').map(([key, value], subIndex) => (
                <td key={subIndex} style={{ padding: '5px 0', borderTop: '1px solid', textAlign: 'center', verticalAlign: 'middle' }}>
                  {typeof value === 'string' && value.includes('T')
                    ? formatTimestamp(value)
                    : value === true
                    ? 'Yes'
                    : value === false
                    ? 'No'
                    : (value as React.ReactNode)}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </Box>
  );
  
  const renderListTable = () => (
    <Box width="400px" maxWidth="80%" sx={{ marginBottom: '30px', fontSize: '0.75em' }}>
      <table style={{ width: '100%', textAlign: 'center', borderCollapse: 'collapse' }}>
        <thead>
          <tr style={{ borderBottom: '1px solid' }}>
            {Object.keys(snapshotData[0]).filter(key => key !== 'Timestamp').map((key) => (
              <th key={key}>{key}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {snapshotData.map((item, index) => (
            <tr key={index}>
              {Object.entries(item).filter(([key]) => key !== 'Timestamp').map(([key, value], subIndex) => (
                <td key={subIndex} style={{ padding: '5px 0', borderTop: '1px solid', textAlign: 'center', verticalAlign: 'middle' }}>
                  {typeof value === 'string' && value.includes('T')
                    ? formatTimestamp(value)
                    : value === true
                    ? 'Yes'
                    : value === false
                    ? 'No'
                    : (value as React.ReactNode)}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </Box>
  );
  
  const renderSummary = () => (
    <Box 
      width="400px" 
      maxWidth="80%" 
      sx={{ marginBottom: '30px', padding: '10px', textAlign: 'center' }}
    >
      {Object.entries(snapshotData[0]).map(([key, value]) => (
        <Typography key={key} sx={{ fontSize: '0.85em' }}>
          <>
            <strong>{key}:</strong> {key === 'TimeStamp' ? formatTimestamp(value as string) : (key === 'Official' ? (value ? 'Yes' : 'No') : (value as React.ReactNode))}
          </>
        </Typography>
      ))}
    </Box>
  );
  
  return (
    <Stack spacing={3} alignItems="center" width="100%" style={{ marginTop: '20px', marginBottom: '550px' }}>
      <Typography variant="h3">Membership</Typography>
  
      <Stack direction="row" spacing={2} sx={{ paddingTop: '10px', width: "400px", maxWidth: "90%" }}>
        <Typography sx={{ paddingTop: "8px", paddingLeft: "5px", fontSize: "0.8em" }}>View</Typography>  
        <Select
          sx={{ height: '35px', width: '125px', fontSize: "0.8em" }}
          fullWidth
          value={view}
          onChange={(e) => setView(e.target.value)}
        >
          <MenuItem value="summary" sx={{ fontSize: "0.8em" }}>Summary</MenuItem>
          <MenuItem value="list" sx={{ fontSize: "0.8em" }}>List</MenuItem>
          <MenuItem value="full" sx={{ fontSize: "0.8em" }}>Full</MenuItem>
        </Select>
        <Typography sx={{ paddingTop: "8px", paddingLeft: "5px", fontSize: "0.8em" }}>Exclude Treasury</Typography>
        <Select
          value={exclude ? 'Yes' : 'No'}
          onChange={(e) => setExclude(e.target.value === 'Yes')}
          sx={{ height: '35px', width: '80px', fontSize: "0.8em" }} // Adjust width as necessary
        >
          <MenuItem value="Yes" sx={{ fontSize: "0.8em" }}>Yes</MenuItem>
          <MenuItem value="No" sx={{ fontSize: "0.8em" }}>No</MenuItem>
        </Select>
      </Stack>
  
      <Stack direction="row" spacing={2} sx={{ paddingTop: '0px', width: "400px", maxWidth: "90%" }}>
        <Typography sx={{ paddingTop: "5px", paddingLeft: "5px", paddingRight: "5px",fontSize: "0.9em" }}>Password:</Typography>
        <TextField
          color="primary"
          variant="standard"
          type="password"
          fullWidth
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Enter password"
        />
      </Stack>
  
      <Stack direction="row" spacing={2} sx={{ paddingTop: '0px', width: "400px", maxWidth: "90%" }}>
        <Typography sx={{ paddingTop: "5px", paddingLeft: "5px", paddingRight: "15px", fontSize: "0.9em" }}>Quantity:</Typography>
        <TextField
          color="primary"
          variant="standard"
          fullWidth
          value={quantity}
          onChange={(e) => setQuantity(e.target.value)}
          placeholder="Enter quantity"/>
      </Stack>
  
      <Stack direction="column" spacing={2} sx={{ paddingTop: '20px' }}>
        <Button
          variant="contained"
          sx={{ backgroundColor: 'blurple.main', color: 'text.primary', width: '200px' }}
          onClick={handleViewSnapshot}
          disabled={loadingView}>
          {loadingView ? <CircularProgress size={24} /> : 'VIEW SNAPSHOT'}
        </Button>
        <Button
          variant="contained"
          sx={{ backgroundColor: 'blurple.main', color: 'text.primary', width: '200px' }}
          onClick={handleCreateSnapshot}
          disabled={loadingSnapshot}>
          {loadingSnapshot ? <CircularProgress size={24} /> : 'CREATE SNAPSHOT'}
        </Button>
        <Button
          variant="contained"
          sx={{ backgroundColor: 'blurple.main', color: 'text.primary', width: '200px' }}
          onClick={handleMintTickets}
          disabled={password === '' || isNaN(Number(quantity)) || Number(quantity) < 1 || Number(quantity) > 1000 || loadingMint}>
          {loadingMint ? <CircularProgress size={24} /> : 'MINT TICKETS'}
        </Button>
        <Button
          variant="contained"
          sx={{ backgroundColor: 'blurple.main', color: 'text.primary', width: '200px' }}
          onClick={handleAirdropTickets}
          disabled={password === '' || loadingAirdrop}>
          {loadingAirdrop ? <CircularProgress size={24} /> : 'AIRDROP TICKETS'}
        </Button>
      </Stack>
  
      <Box width="400px" maxWidth="80%" sx={{ marginTop: '0px' }}>
        <TextField
          color="primary"
          variant="standard"
          fullWidth
          value={result}
          multiline
          rows={2}
          InputProps={{
            readOnly: true,
            disableUnderline: true,
          }}
          sx={{
            '& .MuiInputBase-input': {
              color: 'text.secondary',
              fontSize: '0.75rem',
              fontWeight: 300,
              textAlign: 'center',
            }
          }}
        />
      </Box>
  
      {view === 'summary' && snapshotData.length > 0 && renderSummary()}
      {view === 'list' && snapshotData.length > 0 && renderListTable()}
      {view === 'full' && snapshotData.length > 0 && renderFullTable()}
    </Stack>
  );
};

export default Membership;
