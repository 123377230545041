import { AppBar, Toolbar, Typography } from "@mui/material";
import { ConnectButton, ConnectedAccounts } from "../hashconnect/hashconnect-client";
import '../../App.css';

export const Navbar = () => {
  return (
    <AppBar position="sticky" color="secondary">
      <Toolbar style={{ display: 'flex', justifyContent: 'space-between', height: '40px', minHeight: '40px' }} >
        <Typography variant="subtitle1" noWrap component="div"
                    style={{ marginTop: '3px', alignItems: 'center', justifyContent: 'center', fontSize: '1em', fontWeight: 'bold' }}>
          ELFC
        </Typography>
        <span className="Account">
          <ConnectedAccounts />
        </span>
        <ConnectButton />
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;