// src/components/layout/Router.tsx

import { Box, Stack, useTheme } from "@mui/material";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { AppStore } from "../../store";

import Navbar from "./Navbar";
import Layout from "./Layout";
import Footer from "./Footer";

import Home from "../../pages/Home";
import Admin from '../../pages/admin/Admin';
import Settings from '../../pages/admin/Settings';
import Diagnostics from '../../pages/admin/Diagnostics';
import Raffle from '../../pages/admin/Raffle';
import Dashboard from '../../pages/Dashboard';
import Membership from '../../pages/admin/Membership';
import Tickets from '../../pages/admin/Tickets';
import CreateNFT from '../../pages/admin/CreateNFT';

export const Router = () => {
  const theme = useTheme();
  const { accountInfo, isConnected } = useSelector((state: AppStore) => state.hashconnect);

  return (
    <BrowserRouter>
      <Stack minHeight="100vh" bgcolor={theme.palette.primary.main}>
        <Navbar />
        <Box flex={1} display="flex" flexDirection="column">
          <Box flex={1} overflow="auto">
            <Routes>
              <Route path="/" element={<Layout />}>
                <Route index element={<Home />} />
                <Route path="/admin" element={accountInfo?.Treasury ? <Admin /> : <Navigate to="/" />} />
                <Route path="/admin/settings" element={accountInfo?.Treasury ? <Settings /> : <Navigate to="/" />} />
                <Route path="/admin/diagnostics" element={accountInfo?.Treasury ? <Diagnostics /> : <Navigate to="/" />} />
                <Route path="/admin/create" element={accountInfo?.Treasury ? <CreateNFT /> : <Navigate to="/" />} />
                <Route path="/admin/raffle" element={accountInfo?.Treasury ? <Raffle /> : <Navigate to="/" />} />
                <Route path="/admin/membership" element={accountInfo?.Treasury ? <Membership /> : <Navigate to="/" />} />
                <Route path="/admin/tickets" element={accountInfo?.Treasury ? <Tickets /> : <Navigate to="/" />} />
                <Route path="/dashboard" element={isConnected ? <Dashboard /> : <Navigate to="/" />} />
              </Route>
            </Routes>
          </Box>
          <Footer />
        </Box>
      </Stack>
    </BrowserRouter>
  );
};
