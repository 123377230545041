import { Stack, Box, Typography, Button } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { AppStore, actions } from "../store";
import { useNavigate } from 'react-router-dom';
import { useEffect } from "react";
import axios from 'axios';
import logo from '../assets/ELFC_900.png'
import '../App.css';

const ApiKey = process.env.REACT_APP_API_KEY;

export const Home = () => {
  const { accountIds: connectedAccountIds, isConnected, accountInfo } = useSelector(
    (state: AppStore) => state.hashconnect
  );
  const dispatch = useDispatch();

  const fetchAccountInfo = async (accountId: string) => {
    if (!ApiKey) {
      console.error("API key is not defined");
      return;
    }

    try {
      const response = await axios.get(`https://elfc-server.azurewebsites.net/api/account/${accountId}`, {
        headers: {
          'x-api-key': ApiKey
        }
      });
      dispatch(actions.hashconnect.setAccountInfo(response.data));
      console.log("Fetched account info:", response.data);
    } catch (error) {
      console.error("Error fetching account information:", error);
      dispatch(actions.hashconnect.setAccountInfo(null));
    }
  };

  useEffect(() => {
    if (isConnected && connectedAccountIds.length > 0) {
      fetchAccountInfo(connectedAccountIds[0]);
    }
  }, [isConnected, connectedAccountIds]);

  const handleDisconnect = () => {
    dispatch(actions.hashconnect.clearAccountInfo());
    // Logic to handle the disconnect from HashConnect (change) new
  };

  const GoToAdminButton = () => {
    const navigate = useNavigate();

    const goToAdmin = () => {
      navigate('/admin');
    };

    return (
      <Button
        variant="contained"
        color={"blurple" as any}
        onClick={goToAdmin}
        sx={{ padding: '5px 5px', minWidth: 'auto', width: '150px' }}
      >
        Admin Area
      </Button>
    );
  };

  const GoToGuestButton = () => {
    const navigate = useNavigate();

    const goToGuest = () => {
      navigate('/dashboard');
    };

    return (
      <Button
        variant="contained"
        color={"blurple" as any}
        onClick={goToGuest}
        sx={{ padding: '5px 5px', minWidth: 'auto', width: '150px' }}
      >
        Dashboard 
      </Button>
    );
  };

  console.log("Account Information:", accountInfo);
  console.log("Treasury:", accountInfo?.Treasury);

  return (
    <Stack spacing={1} alignItems="center" width="100%" style={{ paddingTop: '20px' }}>
      <img src={logo} className="Home-logo" alt="logo" />
      <Typography variant="h3" style={{ fontWeight: 'bold' }}>Earthlings Fan Club</Typography>

      {!isConnected && <Typography>Connect your wallet</Typography>}
      {isConnected && (
        <Stack width="400px" paddingTop= "30px" maxWidth="80%" spacing={1} pt={3} alignItems="center">
          {accountInfo ? (
            <>
              <Box display="flex" justifyContent="center" gap={2} pt={2}>
                {accountInfo.Treasury ? <GoToAdminButton /> : <Typography>No Admin Access</Typography>}
                <GoToGuestButton />
              </Box>
            </>
          ) : (
            <Box display="flex" justifyContent="center" gap={2} pt={2}>
              <GoToGuestButton />
            </Box>
          )}
        </Stack>
      )}
    </Stack>
  );
};

export default Home;
