import { Button } from "@mui/material";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getConnectedAccountIds, hc, hcInitPromise, getBalance } from "../../services/hashconnect";
import { actions, AppStore } from "../../store";
import { useNavigate } from "react-router-dom";
import { formatBalance } from "../../helpers/numberUtils";


export const fetchAccountBalance = async (dispatch: any) => {
  const connectedAccountIds = getConnectedAccountIds();
  if (connectedAccountIds.length > 0) {
    const accountId = connectedAccountIds[0];
    try {
      const accountBalance = await getBalance(accountId);
      console.log("Fetched account balance:", accountBalance.toString());
      dispatch(actions.hashconnect.setAccountBalance(accountBalance.toString()));
    } catch (error) {
      console.error("Error fetching account balance:", error);
      dispatch(actions.hashconnect.setAccountBalance(null));
    }
  }
};

export const HashConnectClient = () => {
  const dispatch = useDispatch();
  const syncWithHashConnect = useCallback(async () => {
    const connectedAccountIds = getConnectedAccountIds();
    console.log("Connected Account IDs:", connectedAccountIds);
    if (connectedAccountIds.length > 0) {
      dispatch(
        actions.hashconnect.setAccountIds(
          connectedAccountIds.map((o) => o.toString())
        )
      );
      dispatch(actions.hashconnect.setIsConnected(true));
      dispatch(actions.hashconnect.setPairingString(hc.pairingString ?? ""));
      
      // Set the network
      const network = hc.ledgerId.toString();
      console.log("Network:", network); // Add console log
      dispatch(actions.hashconnect.setNetwork(network));

      // Fetch and set account balance
      try {
        const accountId = connectedAccountIds[0];
        const accountBalance = await getBalance(accountId);
        console.log("Account Balance:", accountBalance.toString()); // Add console log
        dispatch(actions.hashconnect.setAccountBalance(accountBalance.toString()));
      } catch (error) {
        console.error('Error fetching account balance:', error);
      }
    } else {
      dispatch(actions.hashconnect.setAccountIds([]));
      dispatch(actions.hashconnect.setIsConnected(false));
      dispatch(actions.hashconnect.setPairingString(hc.pairingString ?? ""));
      dispatch(actions.hashconnect.setAccountBalance(null)); // Clear account balance if no accounts are connected
      dispatch(actions.hashconnect.setNetwork("")); // Clear network if no accounts are connected
    }
  }, [dispatch]);

  syncWithHashConnect();
  hcInitPromise.then(() => {
    syncWithHashConnect();
  });
  hc.pairingEvent.on(() => {
    syncWithHashConnect();
  });
  hc.disconnectionEvent.on(() => {
    syncWithHashConnect();
  });
  hc.connectionStatusChangeEvent.on(() => {
    syncWithHashConnect();
  });

  return null;
};

export const ConnectButton = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isConnected } = useSelector(
    (state: AppStore) => state.hashconnect
  );

  const handleDisconnect = async () => {
    try {
      if (isConnected) {
        await hcInitPromise;
        if (isConnected) {
          if (getConnectedAccountIds().length > 0) {
            try {
              hc.disconnect();
              dispatch(actions.hashconnect.clearAccountInfo());
              navigate("/");
            } catch (error) {
              console.error('Error disconnecting:', error);
            }
          }
        }
      } else {
        hc.openPairingModal();
      }
    } catch (error) {
      console.error('Error in onClick handler:', error);
    }
  };

  return (
    <Button color={"blurple" as any} variant="contained" style={{ 
      height: '25px', 
      fontSize: '0.7em',  
      textTransform: 'none', 
      alignItems: 'center', 
      justifyContent: 'center', 
      paddingTop : '13px', 
      paddingBottom : '11px',
      paddingLeft : '15px', 
      paddingRight : '15px',
      width : '100px',
      color: 'white'
    }}
      onClick={handleDisconnect}
    >
      {isConnected
        ? "DISCONNECT"
        : "CONNECT"}
    </Button>
  );
};

export const ConnectedAccounts = () => {
  const { isConnected, accountIds: connectedAccountIds } = useSelector(
    (state: AppStore) => state.hashconnect
  );

  return (
      <span>
        {isConnected
          ? `${''}${connectedAccountIds.join(', ')}`
          : ""}
      </span>
  );
};

export const ConnectedNetwork = () => {
  const { isConnected, network } = useSelector(
    (state: AppStore) => state.hashconnect
  );

  let networkLabel = "";

  if (isConnected) {
    networkLabel = network === "testnet" ? "Testnet" : 
                   network === "mainnet" ? "Mainnet" : "";
    console.log("Network Label:", networkLabel); // Add console log
  }

  return (
      <span>
        {isConnected
          ? `${networkLabel}`
          : ""}
      </span>
  );
};

export const AccountBalance = () => {
  const { isConnected, accountBalance } = useSelector(
    (state: AppStore) => state.hashconnect
  );

  console.log("Account Balance Display:", accountBalance); // Add console log

  // Format the account balance
  const formattedBalance = accountBalance !== null ? `${formatBalance(parseFloat(accountBalance))} ℏ` : "";

  return (
    <span>
      {isConnected && accountBalance !== null
        ? `${formattedBalance}`
        : ""}
    </span>
  );
};
