import axios from 'axios';
import EventSource from 'eventsource';
// import dotenv from 'dotenv';
// import EventSource from 'eventsource';

//npm install eventsource
//npm i --save-dev @types/eventsource

// dotenv.config();

const ApiKey = process.env.REACT_APP_API_KEY;

export const getHeaders = () => {
  if (!ApiKey) {
    throw new Error("API key is not defined");
  }
  return { 'x-api-key': ApiKey, 'Content-Type': 'application/json' };
};

// Functions for the Diagnostics.tsx page

export const checkServer = async () => {
  try {
    const response = await axios.get('https://elfc-server.azurewebsites.net/api/status/server', {
      headers: getHeaders()
    });
    return response.data;
  } catch (error) {
    console.error('Error checking server status:', error);
    throw error;
  }
};

export const checkDatabase = async () => {
  try {
    const response = await axios.get('https://elfc-server.azurewebsites.net/api/status/db', {
      headers: getHeaders()
    });
    return response.data;
  } catch (error) {
    console.error('Error checking database status:', error);
    throw error;
  }
};

export const getAccount = async (accountId: string) => {
  try {
    const response = await axios.get(`https://elfc-server.azurewebsites.net/api/account/${accountId}`, {
      headers: getHeaders()
    });
    return response.data;
  } catch (error) {
    console.error('Error getting account info:', error);
    throw error;
  }
};

// Functions for the Settings.tsx page

export const patchAccount = async (accountId: string, privateKey: string, password: string) => {
  try {
    const response = await axios.patch(`https://elfc-server.azurewebsites.net/api/account/${accountId}/update`, {
      accountId,
      privateKey,
      password
    }, {
      headers: getHeaders()
    });
    return response.data;
  } catch (error) {
    console.error('Error updating account:', error);
    throw error;
  }
};

export const verifyKey = async (accountId: string, password: string, network: string) => {
  try {
    const response = await axios.post('https://elfc-server.azurewebsites.net/api/account/verify/key', {
      accountId,
      password,
      network
    }, {
      headers: getHeaders()
    });
    return response.data;
  } catch (error) {
    console.error('Error verifying key:', error);
    throw error;
  }
};

export const verifyPassword = async (accountId: string, password: string) => {
  try {
    const response = await axios.post('https://elfc-server.azurewebsites.net/api/account/verify/password', {
      accountId,
      password
    }, {
      headers: getHeaders()
    });
    return response.data;
  } catch (error) {
    console.error('Error verifying password:', error);
    throw error;
  }
};

// Functions for the Membership.tsx page

export const createMemberSnapshot = async (accountId: string, official: boolean) => {
  try {
    const response = await axios.post('https://elfc-server.azurewebsites.net/api/snapshot/member/create', {
      accountId,
      official
    }, {
      headers: getHeaders()
    });
    return response.data;
  } catch (error) {
    console.error('Error creating member snapshot:', error);
    throw error;
  }
};

export const getMemberSnapshot = async (accountId: string, view: string, timestamp: string | null, official: boolean, exclude: string) => {
  try {
    const response = await axios.post('https://elfc-server.azurewebsites.net/api/snapshot/member/retrieve', {
      accountId,
      view,
      timestamp,
      official,
      exclude
    }, {
      headers: getHeaders()
    });
    return response.data;
  } catch (error) {
    console.error('Error getting member snapshot:', error);
    throw error;
  }
};

export const bulkMint = async (accountId: string, password: string, quantity: number) => {
  const url = 'https://elfc-server.azurewebsites.net/api/ticket/mint';
  
  try {
    const response = await axios.post(url, {
      accountId,
      password,
      quantity
    }, {
      headers: getHeaders()
    });

    return response.data; 

  } catch (error: any) {
    throw new Error(`Error initiating minting process: ${error.message}`);
  }
};

export const mintTickets = async (accountId: string, password: string, quantity: number) => {
  const url = 'https://elfc-server.azurewebsites.net/api/ticket/mint';
  
  try {
    const response = await axios.post(url, {
      accountId,
      password,
      quantity
    }, {
      headers: getHeaders()
    });

    return response.data; 

  } catch (error : any) {
    throw new Error(`Error initiating mint process: ${error.message}`);
  }
};

export const airdropTickets = async (accountId: string, password: string) => {
  const url = 'https://elfc-server.azurewebsites.net/api/ticket/airdrop'

  try {
    const response = await axios.post(url, {
      accountId,
      password
    }, {
      headers: getHeaders()
    });

    return response.data;

  } catch (error : any) {
    throw new Error(`Error initiating airdrop process: ${error.message}`);
  }
};

// Functions for the Tickets.tsx page

export const createTicketSnapshot = async (accountId: string, official: boolean) => {
  try {
    const response = await axios.post('https://elfc-server.azurewebsites.net/api/snapshot/ticket/create', {
      accountId,
      official
    }, {
      headers: getHeaders()
    });
    return response.data;
  } catch (error) {
    console.error('Error creating ticket snapshot:', error);
    throw error;
  }
};

export const getTicketSnapshot = async (accountId: string, view: string, timestamp: string | null, official: boolean, exclude: string) => {
  try {
    const response = await axios.post('https://elfc-server.azurewebsites.net/api/snapshot/ticket/retrieve', {
      accountId,
      view,
      timestamp,
      official,
      exclude
    }, {
      headers: getHeaders()
    });
    return response.data;
  } catch (error) {
    console.error('Error getting ticket snapshot:', error);
    throw error;
  }
};

export const wipeTickets = async (accountId: string, password: string) => {
  const url = 'https://elfc-server.azurewebsites.net/api/ticket/wipe'
  
  try {
    const response = await axios.post(url, {
      accountId,
      password
    }, {
      headers: getHeaders()
    });

    return response.data;

  } catch (error : any) {
    throw new Error(`Error initiating wipe process: ${error.message}`);
  }
};

export const burnTickets = async (accountId: string, password: string) => {
  const url = 'https://elfc-server.azurewebsites.net/api/ticket/burn'
  
  try {
    const response = await axios.post(url, {
      accountId,
      password
    }, {
      headers: getHeaders()
    });

    return response.data;

  } catch (error : any) {
    throw new Error(`Error initiating burn process: ${error.message}`);
  }
};

//Get NFT information
export const queryNFT = async (accountId: string, names: string) => {
  try {
    const response = await axios.post('https://elfc-server.azurewebsites.net/api/nft/query', {
      accountId,
      nftNames: [names]
    }, {
      headers: getHeaders()
    });
    return response.data;
  } catch (error) {
    console.error('Error querying NFT:', error);
    throw error;
  }
};

//Clear snapshot and airdrop tables
export const clearSnapshot = async () => {
  try {
    const response = await axios.post('https://elfc-server.azurewebsites.net/api/snapshot/clear', {}, {
      headers: getHeaders()
    });
    return response.data;
  } catch (error) {
    console.error('Error clearing snapshot:', error);
    throw error;
  }
};
