// Dashboard.tsx
//import React from 'react';
import { Stack, Typography, } from '@mui/material';

const Dashboard = () => {
  return (
    <Stack spacing={3} alignItems="center" width="100%" style={{ marginTop: '20px' }}>
      <Typography variant="h3">Dashboard</Typography>

      <Stack direction="column" spacing={2} sx={{ paddingTop: '120px' }}>
        <Typography color="blurple.main" variant="body1">Coming soon...</Typography>
      </Stack>
    </Stack>
  );
};
export default Dashboard;