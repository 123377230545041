import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";

export const Layout = () => {
  return (
    <Box p={3} pb={10} flex={1} overflow="auto">
      <Outlet />
    </Box>
  );
};

export default Layout;
