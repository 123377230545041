import { Stack, Box, Typography, Button } from "@mui/material";
import { useSelector } from "react-redux";
import { AppStore } from "../../store";
import { useNavigate } from 'react-router-dom';

export const Admin = () => {
  const { accountIds: connectedAccountIds, isConnected } = useSelector(
    (state: AppStore) => state.hashconnect
  );

  const GoToSettingsButton = () => {
    const navigate = useNavigate();
  
    const goToSettings = () => {
      navigate('/admin/settings');
    };
  
    return (
      <Button variant="contained" color={"blurple" as any} onClick={goToSettings}>
        Settings
      </Button>
    );
  };

  const GoToDiagnosticsButton = () => {
    const navigate = useNavigate();
  
    const goToDiagnostics = () => {
      navigate('/admin/diagnostics');
    };
  
    return (
      <Button variant="contained" color={"blurple" as any} onClick={goToDiagnostics}>
        Diagnostics
      </Button>
    );
  };

  const GoToRaffleButton = () => {
    const navigate = useNavigate();
  
    const goToRaffle = () => {
      navigate('/admin/raffle');
    };
  
    return (
      <Button variant="contained" color={"blurple" as any} onClick={goToRaffle}>
        Raffle
      </Button>
    );
  };

  const GoToMembershipButton = () => {
    const navigate = useNavigate();
  
    const goToMembership = () => {
      navigate('/admin/membership');
    };
  
    return (
      <Button variant="contained" color={"blurple" as any} onClick={goToMembership}>
        Membership
      </Button>
    );
  };

  const GoToTicketsButton = () => {
    const navigate = useNavigate();
  
    const goToTickets = () => {
      navigate('/admin/tickets');
    };
  
    return (
      <Button variant="contained" color={"blurple" as any} onClick={goToTickets}>
        Tickets
      </Button>
    );
  };

  const GoToCreateNFTButton = () => {
    const navigate = useNavigate();

    const goToCreateNFT = () => {
      navigate('/admin/create');
    };

    return (
      <Button variant="contained" color={"blurple" as any} onClick={goToCreateNFT}>
        Create NFT
      </Button>
    );
  };

  return (
    <Stack spacing={3} alignItems="center" width="100%" style={{ marginTop: '20px' }}>
      <Typography variant="h3">Admin Area</Typography>

      {!isConnected && <Typography>NONE</Typography>}
      {isConnected && (
        <Stack direction="column" spacing={2} sx={{ paddingTop: '10px' }}>
          <GoToDiagnosticsButton />
          <GoToSettingsButton />
          <GoToCreateNFTButton />
          <GoToMembershipButton />
          <GoToTicketsButton />
          <GoToRaffleButton />
        </Stack>
      )}
    </Stack>
  );
};

export default Admin;
