import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "./theme";
import { store } from "./store";
import { Buffer } from "buffer";
import { HashConnectClient } from "./components/hashconnect/hashconnect-client";
import { Router } from "./components/layout/Router"; // Import the Router component

window.Buffer = window.Buffer || Buffer;

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <HashConnectClient />
      <ThemeProvider>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <Router /> {/* Use the Router component instead of the App component */}
      </ThemeProvider>
    </Provider>
  </React.StrictMode>
);
