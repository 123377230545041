import React, { useState } from 'react';
import { Stack, Box, Typography, Button, TextField } from '@mui/material';
import { useSelector } from 'react-redux';
import { AppStore } from '../../store';
import { checkServer, checkDatabase, getAccount, clearSnapshot } from '../../helpers/serverCalls';

const Diagnostics = () => {
  const [result, setResult] = useState('');
  const { accountIds } = useSelector((state: AppStore) => state.hashconnect);
  const accountId = accountIds[0];

  const handleCheckServer = async () => {
    try {
      const response = await checkServer();
      setResult(`${response}`);
    } catch (error) {
      console.error('Error checking server status:', error);
      setResult((error as Error).message);
    }
  };

  const handleCheckDatabase = async () => {
    try {
      const response = await checkDatabase();
      setResult(`${response}`);
    } catch (error) {
      console.error('Error checking database status:', error);
      setResult((error as Error).message);
    }
  };

  const handleGetAccount = async () => {
    try {
      const response = await getAccount(accountId);
      const formattedResponse = JSON.stringify(response, null, 2)
        .replace(/^{\n/, "")
        .replace(/\n}$/, "")
        .replace(/"PrivateKey": "(.*?)"/, (match, p1) => `"PrivateKey": "${p1 ? "***" : "Not Set"}"`)
        .replace(/"Password": "(.*?)"/, (match, p1) => `"Password": "${p1 ? "***" : "Not Set"}"`)
        .replace(/\"/g, "")
        .replace(/,/g, "");
      setResult(formattedResponse);
    } catch (error) {
      console.error('Error getting account info:', error);
      setResult((error as Error).message);
    }
  };

  const handleClearSnapshot = async () => {
    try {
      const response = await clearSnapshot();
      setResult(`${response}`);
    } catch (error) {
      console.error('Error clearing snapshot:', error);
      setResult((error as Error).message);
    }
  };
  

  return (
    <Stack spacing={3} alignItems="center" width="100%" style={{ marginTop: '20px', marginBottom: '-50px' }}>
      <Typography variant="h3">Diagnostics</Typography>

      <Stack direction="column" spacing={2} sx={{ paddingTop: '30px' }}>
        <Button
          variant="contained"
          sx={{ backgroundColor: 'blurple.main', color: 'text.primary', width: '200px' }}
          onClick={handleCheckServer}
        >
          Check Server
        </Button>
        <Button
          variant="contained"
          sx={{ backgroundColor: 'blurple.main', color: 'text.primary', width: '200px' }}
          onClick={handleCheckDatabase}
        >
          Check Database
        </Button>
        <Button
          variant="contained"
          sx={{ backgroundColor: 'blurple.main', color: 'text.primary', width: '200px' }}
          onClick={handleGetAccount}
        >
          Get Account
        </Button>
        <Button
          variant="contained"
          sx={{ backgroundColor: 'blurple.main', color: 'text.primary', width: '200px' }}
          onClick={handleClearSnapshot}
        >
          Clear Snapshot
        </Button>
      </Stack>

      <Box width="400px" maxWidth="80%" sx={{ paddingTop: '10px' }}>
        <TextField
          color="primary"
          variant="standard"
          fullWidth
          value={result}
          multiline
          rows={9}
          InputProps={{
            readOnly: true,
            disableUnderline: true,
          }}
          sx={{
            '& .MuiInputBase-input': {
              color: 'text.secondary',
              fontSize: '0.75rem',
              fontWeight: 300,
              textAlign: 'center',
            }
          }}
        />
      </Box>
    </Stack>
  );
};

export default Diagnostics;
