import { AccountId, LedgerId, Transaction, AccountBalanceQuery, Client, Hbar } from "@hashgraph/sdk";
import { HashConnect } from "hashconnect";

const network = process.env.REACT_APP_NETWORK;

if (!network) {
    throw new Error('REACT_APP_NETWORK is not defined');
}

const appMetadata = {
    name: "ELFC",
    description: "Earthlings Fan Club",
    icons: ["https://i.ibb.co/dM6TVb6/ELFC.png"],
    url: "http://elfc.app"
};
const projectId = "9bd017354bf56f19d906035d1dd0362d";

export const hc = new HashConnect(
    LedgerId.fromString(network),
    projectId,
    appMetadata,
    true
);

export const getConnectedAccountIds = () => {
    return hc.connectedAccountIds;
};

export const hcInitPromise = hc.init();

export const signTransaction = async (
    accountIdForSigning: AccountId,
    trans: Transaction
) => {
    await hcInitPromise;

    const accountIds = getConnectedAccountIds();
    if (!accountIds) {
        throw new Error("No connected accounts");
    }

    const isAccountIdForSigningPaired = accountIds.some(
        (id) => id.toString() === accountIdForSigning.toString()
    );
    if (!isAccountIdForSigningPaired) {
        throw new Error(`Account ${accountIdForSigning} is not paired`);
    }

    const result = await hc.signTransaction(accountIdForSigning, trans);
    return result;
};

export const executeTransaction = async (
    accountIdForSigning: AccountId,
    trans: Transaction
) => {
    await hcInitPromise;

    const accountIds = getConnectedAccountIds();
    if (!accountIds) {
        throw new Error("No connected accounts");
    }

    const isAccountIdForSigningPaired = accountIds.some(
        (id) => id.toString() === accountIdForSigning.toString()
    );
    if (!isAccountIdForSigningPaired) {
        throw new Error(`Account ${accountIdForSigning} is not paired`);
    }

    const result = await hc.sendTransaction(accountIdForSigning, trans);
    return result;
};

export const signMessages = async (
    accountIdForSigning: AccountId,
    message: string
) => {
    await hcInitPromise;

    const accountIds = getConnectedAccountIds();
    if (!accountIds) {
        throw new Error("No connected accounts");
    }

    const isAccountIdForSigningPaired = accountIds.some(
        (id) => id.toString() === accountIdForSigning.toString()
    );
    if (!isAccountIdForSigningPaired) {
        throw new Error(`Account ${accountIdForSigning} is not paired`);
    }

    const result = await hc.signMessages(accountIdForSigning, message);
    return result;
};

// Initialize the client based on the network
let client: Client;

if (network === "testnet") {
    client = Client.forTestnet();
} else if (network === "mainnet") {
    client = Client.forMainnet();
} else {
    throw new Error("Unsupported Hedera network");
}

export const getBalance = async (accountId: AccountId): Promise<Hbar> => {
    await hcInitPromise;
    const accountBalance = await new AccountBalanceQuery()
        .setAccountId(accountId)
        .execute(client);
    return accountBalance.hbars;
};