import { AppBar, Toolbar, Button } from "@mui/material";
import { useNavigate, useLocation } from 'react-router-dom';
import { ConnectedNetwork, AccountBalance, fetchAccountBalance } from "../hashconnect/hashconnect-client"; // Import the ConnectedNetwork, AccountBalance components and fetchAccountBalance
import { useDispatch } from "react-redux";

export const Footer = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleBackClick = () => {
    navigate(-1); // This will take the user back to the previous page
  };

  const handleBalanceClick = () => {
    fetchAccountBalance(dispatch);
  };

  return (
    <AppBar position="fixed" color="secondary" style={{ top: 'auto', bottom: 0 }}>
      <Toolbar style={{ display: 'flex', justifyContent: 'space-between', height: '40px', minHeight: '40px' }}>
        <span style={{ fontSize: '0.8em' }}>
          <ConnectedNetwork /> {/* Display the connected network */}
        </span>
        <span 
          style={{ fontSize: '0.8em', textAlign: 'center', cursor: 'pointer' }}
          onClick={handleBalanceClick}
        >
          <AccountBalance /> {/* Display the account balance */}
        </span>
        {location.pathname !== "/" && (
          <Button 
            color={"blurple" as any} 
            variant="contained"
            style={{ 
              height: '25px', 
              fontSize: '0.7em',  
              textTransform: 'none', 
              alignItems: 'center', 
              justifyContent: 'center', 
              paddingTop : '13px', 
              paddingBottom : '11px',
              paddingLeft : '15px', 
              paddingRight : '15px',
              color: 'white'
            }} 
            onClick={handleBackClick}
          >
            BACK
          </Button>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Footer;
